import { mapGetters } from "vuex";
import { getDictionary } from "@/utils/dictionary";
import { canVerb } from "@/utils/permissions";
import { prepareFilterQueryString, getInputType } from "@/utils/forms";

export default {
  data: () => ({
    customFilters: [], // use to add custom filters dynamically
  }),
  methods: {
    getDictionary,
    canVerb,

    convertFromCustomAttributesToFilters(array) {
      let ret = [];
      if (!array) return ret;
      // console.log("convertFromCustomAttributesToFilters: ", array);
      array.forEach((element) => {
        if (element.is_customizable && element.is_customizable === "N") return;
        if (element.is_searchable && element.is_searchable === "N") return;
        // if (element.last_delete) return;
        if (element.is_hidden && element.is_hidden === "Y") return;
        let e = {
          key: element.key,
          label: element.text,
          class: element.class,
          is_searchable: element.is_searchable,
          is_hidden: element.is_hidden,
          type: element.type,
        };
        ret.push(e);
      });
      return ret;
    },
    resetFilter() {
      this.filter = this.initFilter();
      this.customFilters.forEach((element) => {
        if (getInputType(element) === "text") {
          if (!this.filter.byCustom) {
            this.$set(this.filter, "byCustom", {});
          }
          this.$set(this.filter.byCustom, element.key, {
            value: null,
            likewise: 1,
            filter: "byAttribute",
          });
        } else {
          this.$set(this.filter.byAttribute, element.key, null);
        }
      });
    },
    resetPagination() {
      if (this.$refs[this.tableRef].totalItems) {
        this.$refs[this.tableRef].perPage = this.$refs[this.tableRef].setting()(
          "attribute_RPP_value"
        );
        this.$refs[this.tableRef].currentPage = 1;
      }
    },
    resetQuickSearch() {
      // per il momento non ancora UTILIZZATA
      // per poterla "attivare" ci sono cose da sistemare:
      // 1. i "nomi" dei campi formLabel e formData non seguono un pattern globalmente,
      // 2. le viste possono implementare da nessuna a tutte le quicksearch
      // solo che nel mixin non posso sapere quali sono (lo so soltanto se la quickSearch è salvata)
      // a meno di basarmi su qualche novità introdotta col punto 1.
      // TODO: il refactor sarebbe:
      //  - adottare un pattern globale per formLabel e formData (e se c'è qualche altro campo coinvolto)
      //    in modo tale che nel mixin si possa capire quali quickSearch sono presenti
      //  - i metodi reset*Id() vanno chiamati qui
      //  - togliere le chiamate dal meodo onClearFilter() nelle viste che includono questo mixin

      // quicksearch
      let criteria = this.loadQuickSearch(this.filterName);
      if (criteria) {
        // qui posso anche non sapere quali quickSearch sono presenti, perché ho tutto.
        for (const value of Object.values(criteria)) {
          this.setValue(this, value.formLabel, null);
          this.setValue(this, value.formData, null);
        }
      } else {
        // qui non c'è la quickSearch salvata, ma devo pur poter pulire i campi
        // non sapendo però quali sono le quickSearch (registry, policy, ) posso solo "tentare":
        for (const model of ["Registry", "Policy"]) {
          try {
            this[`reset${model}Id`]();
          } catch (error) {
            console.info(`quick search model ${model} not found`);
          }
        }
      }
    },
    loadFilter(name) {
      let criteria = this.loadFilterByName()(name);
      if (!criteria) return null;
      return criteria;
    },
    loadQuickSearchGroupExtraInfo(name) {
      let extra_info = this.loadQuickSearchGroupExtraInfoByName()(name);
      if (!extra_info) return null;
      return extra_info;
    },
    loadQuickSearchGrupoExtraInfo(name) {
      let criteria = this.loadQuickSearchByName()(name);
      if (!criteria) return null;
      return criteria;
    },
    loadQuickSearch(name) {
      let criteria = this.loadQuickSearchByName()(name);
      if (!criteria) return null;
      return criteria;
    },
    setValue(obj, keys, value) {
      keys = typeof keys === "string" ? keys.split(".") : keys;
      const key = keys.shift();
      if (keys.length === 0) {
        // obj[key] = value;
        this.$set(obj, key, value);
        return;
      } else if (!Object.hasOwnProperty.call(obj, key)) {
        // obj[key] = {};
        this.$set(obj, key, {});
      }
      this.setValue(obj[key], keys, value);
    },
    hasDefaultFilter() {
      let s = prepareFilterQueryString(this.filter);
      return s.length > 0;
    },
    getDefaultFilterMessage() {
      return this.hasDefaultFilter() ? "Seleziona uno o più filtri" : "";
    },
    ...mapGetters("auth", ["customAttributes"]),
    ...mapGetters("filters", {
      loadFilterByName: "loadByName",
      loadQuickSearchGroupExtraInfoByName: "loadExtraInfoGroupByName",
    }),
    ...mapGetters("quicksearch", { loadQuickSearchByName: "loadByName" }),
  },
  // computed: {
  //   ...mapGetters("auth", ["permissions"]),
  // },
  mounted() {},
  beforeMount() {
    /* Before Mounting the Component it goes and check active filters */
    let criteria = this.loadFilter(this.filterName);
    if (criteria) {
      // binf the filter model with loaded values:
      for (const [key, value] of Object.entries(criteria)) {
        // this.$set(this.filter, key, value); // key is in dot notation, can't do this way!
        this.setValue(this.filter, key, value);
      }
      // this.$set(this.filter, criteria);
      // quicksearch group extra info
      let extra_info = this.loadQuickSearchGroupExtraInfo(this.filterName);
      if (extra_info) {
        this.extra_group_full_info = extra_info;
        this.form.inpt_label_group = criteria.byRegistryGroup.code;
      }
    }
    // quicksearch
    criteria = this.loadQuickSearch(this.filterName);
    if (criteria) {
      // binf the filter model with loaded values:
      for (const value of Object.values(criteria)) {
        // let l = value.formLabel.split(".");
        // let ol = s.reduce((acc, part) => acc && acc[part], this);
        this.setValue(this, value.formLabel, value.label);
        this.setValue(this, value.formData, value.data);
      }
    }
    // this.$nextTick(function() {
    // Code that will run only after the entire view has been rendered
    // Transform custom attributes (remove unuseful properties like is_*)
    if (!this.repository) {
      console.info("CustomFiltersMixin: missing repository");
      return;
    }
    this.customFilters = this.convertFromCustomAttributesToFilters(
      this.customAttributes()(this.repository)
    );
    // Create the dynamic v-model data property using $set:ù

    this.customFilters.forEach((element) => {
      // this.filter.byAttribute[element.key] = null; // this works bad
      // NOTE: since input is created and bound at runtime, must use $set:
      if (getInputType(element) === "text") {
        if (!this.filter.byCustom) {
          this.$set(this.filter, "byCustom", {});
        }

        if (!this.filter.byCustom[element.key]) {
          this.$set(this.filter.byCustom, element.key, {
            value: null,
            likewise: 1,
            filter: "byAttribute",
          });
        }
      } else {
        this.$set(this.filter.byAttribute, element.key, null);
      }
    });
    // });
  },
};
